import React, { useContext } from 'react';
import styled from 'styled-components';
import ThemeContext from '../context/ThemeContext';

const TopHeader = styled.div.attrs({
  className: 'fixed top-0 left-0 right-0 w-screen px-12 xl:px-24 pt-12 z-40',
})``;

const TopHeaderLinks = styled.div.attrs({
  className: 'flex justify-between align-middle mb-6',
})``;

const Header = () => {
  const { dark: [hasDark] } = useContext(ThemeContext);

  return (
    <TopHeader className="font-secondary">
      <TopHeaderLinks>
        <button type="button" onClick={() => { window.scrollTo(0, 0); }} className={`text-xl duration-1000  ${hasDark && 'text-white'}`}>wnzls</button>
        <a href="mailto:hi@wnzl.dev"><h6 className={`text-xl  duration-1000 ${hasDark && 'text-white'}`}>contact</h6></a>
      </TopHeaderLinks>
      <hr className="border-gray-200" />
    </TopHeader>
  );
};

export default Header;
