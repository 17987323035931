import React, { useContext } from 'react';
import ThemeContext from '../context/ThemeContext';

export default () => {
  const { dark: [hasDark] } = useContext(ThemeContext);

  return (
    <div className="w-screen h-screen grid grid-cols-3 fixed top-0 bottom-0 right-0 left-0 -z-1 overflow-hidden pointer-events-none">
      <div className={`border-r duration-1000 border-gray-200 ${hasDark ? 'border-opacity-10' : ''}`} />
      <div className={`border-r duration-1000 border-gray-200 ${hasDark ? 'border-opacity-10' : ''}`} />
      <div />
    </div>
  );
};
