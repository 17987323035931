import React, {
  useState, useCallback, useRef, useEffect, useContext, forwardRef,
} from 'react';
import styled from 'styled-components';
import { motion, useAnimation } from 'framer-motion';
import PropTypes from 'prop-types';
import { PlayCircle } from 'react-feather';

import { useInView } from 'react-intersection-observer';
import ThemeContext from '../context/ThemeContext';
import videographyData from './videographyData';

const DESCRIPTION = 'storytelling via motion pictures. previously have shot short films, events, weddings, and music videos.';

const VideoWrapper = styled.div.attrs({
  className: 'mb-44 z-50 isolate',
})`
  position: relative;
  padding-bottom: ${(props) => (props.sixteen ? 56.25 : 36.23)}%; /* 2.76:1 */
  height: 0;
  overflow: hidden;
  width: 100%;
`;

const IframeVideo = styled.iframe`
    
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const VideoIcon = styled(motion.div).attrs({
  className: 'w-72 h-32  z-10',
})`
  background-image: url('${(props) => props.src}');
  background-size: cover;
  cursor: pointer;
`;

const VideoHoverTitle = styled.div.attrs({
  className: 'w-full h-full flex flex-col justify-center align-center text-sm bg-gray-700 bg-opacity-90 justify-center text-white opacity-0 hover:opacity-100 transition-opacity',
})``;

const VideoRow = ({
  image, name, desc, reverse, videoSrc, handleClickVideoSrc,
}) => {
  const controls = useAnimation();
  const handleClickIcon = useCallback(() => {
    handleClickVideoSrc(videoSrc);
  }, []);
  const [inViewRef, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
    // } else {
    //   controls.start('hidden');
    // }
  }, [inView]);

  return (
    <motion.div ref={inViewRef} className={`flex flex-col xl:${reverse ? 'flex-row-reverse' : 'flex-row'} justify-center items-center mb-24`}>
      <VideoIcon
        src={image}
        whileHover={{ y: -10 }}
        transition={{
          duration: 0.5,
        }}
        initial="hidden"
        animate={controls}
        variants={{
          hidden: { opacity: 0, x: reverse ? 100 : -100 },
          visible: { opacity: 1, x: 0, transition: { duration: 1, type: 'tween', ease: 'easeInOut' } },
        }}
        onClick={handleClickIcon}
      >
        <VideoHoverTitle><PlayCircle className="self-center" /></VideoHoverTitle>

      </VideoIcon>
      <motion.hr
        className="hidden xl:block w-64 border-dashed z-0"
        initial="hidden"
        animate={controls}
        variants={{
          hidden: { opacity: 0, x: reverse ? 300 : -300 },
          visible: {
            opacity: 1,
            x: 0,
            transition: {
              duration: 1.5, type: 'tween', ease: 'easeInOut', delay: 0.5,
            },
          },
        }}
      />
      <motion.div
        initial="hidden"
        animate={controls}
        variants={{
          hidden: { opacity: 0 },
          visible: {
            opacity: 1,
            transition: {
              duration: 1.5, type: 'tween', ease: 'easeInOut', delay: 1,
            },
          },
        }}
        className="mx-8"
      >
        <h6
          className={`text-2xl text-center xl:${reverse ? 'text-right' : 'text-left'} font-bold text-white uppercase mt-4 xl:mt-0`}
        >
          {name}
        </h6>
        <p
          className={`text-md xl:text-xl text-center xl:${reverse ? 'text-right' : 'text-left'} uppercase text-gray-400 max-w-lg`}
        >
          <br className="hidden xl:block" />
          {desc}
        </p>
      </motion.div>
    </motion.div>
  );
};

VideoRow.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  videoSrc: PropTypes.string.isRequired,
  handleClickVideoSrc: PropTypes.func.isRequired,
  reverse: PropTypes.bool,
};

VideoRow.defaultProps = {
  reverse: false,
};

const FadeBox = styled.div`
  background-color: #000000;
  ${'' /* transform: skewY(-5deg) scaleY(1.3); */}
  z-index: -10;
  &:before {
    content: "";
    background-color: #000000;
    position: absolute;
    left: 0;
    right: 0;
    height: 500px;
    top:-250px;
    transform: skewY(5deg)
  }
`;

const Videography = () => {
  const [videoData, setVideoData] = useState(videographyData.find((v) => v.default));
  const [inViewRef, inView] = useInView();
  const { dark: [, toggleDark] } = useContext(ThemeContext);
  const videoRef = useRef(null);

  const setSrcAndScroll = (video) => {
    setVideoData(video);
    videoRef.current.scrollIntoView();
  };

  useEffect(() => {
    if (inView) {
      toggleDark(true);
    } else {
      toggleDark(false);
    }
  }, [inView]);

  return (
    <div ref={inViewRef} className="duration-1000 mt-96 relative opacity-100  ease-in-out  w-full min-h-screen flex flex-col items-center pb-96" id="videography">
      <FadeBox className="bg-black absolute -mb-48 left-0 top-0 bottom-0 right-0 z-10" />
      <div className="flex flex-row w-full ">
        <div className="container" />
        <div className="container flex z-10 mb-44">
          <div className="container p-12 xl:p-0">
            <motion.h1
              className="text-5xl lg:text-6xl xl:text-7.5xl font-display font-bold text-white"
              whileHover={{ y: -10 }}
              transition={{
                duration: 0.5,
              }}
            >
              videographer
            </motion.h1>
            <div className="container my-7 max-w-xl text-right md:text-left text-white">
              <p className="text-2xl uppercase">
                {DESCRIPTION}
              </p>
            </div>
          </div>
        </div>
      </div>
      <VideoWrapper
        name="videoPlayer"
        id="videoPlayer"
        ref={videoRef}
        sixteen={!videoData.anamorphic}
      >

        <IframeVideo height="696" width="1920" src={videoData.src} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
      </VideoWrapper>
      <div className="flex flex-col items-center w-full">
        {
          videographyData.map((video, idx) => (
            <VideoRow
              handleClickVideoSrc={video.newTab ? () => window.open(video.tabSrc, '_blank').focus() : () => setSrcAndScroll(video)}
              videoSrc={video.src}
              image={video.image}
              name={video.name}
              desc={video.desc}
              reverse={idx % 2 === 0}
            />
          ))
}
        {/* <VideoRow handleClickVideoSrc={setSrcAndScroll} videoSrc="https://www.youtube.com/embed/S-21IPuACnE?rel=0&modestbranding=1&autohide=1&showinfo=0&autoplay=1" reverse image={hlne} name="His Love Never Ends (2020)" desc="sony a7iii, samyang 35mm, 50mm" />
        <VideoRow handleClickVideoSrc={setSrcAndScroll} videoSrc="https://www.youtube.com/embed/UwcrPIqQJOE?rel=0&modestbranding=1&autohide=1&showinfo=0&autoplay=1" image={newgrad} name="New Grad (2020)" desc="sony fs5, sirui 50mm anamorphic" />
        <VideoRow handleClickVideoSrc={setSrcAndScroll} videoSrc="https://www.youtube.com/embed/BVXAQADq5go?rel=0&modestbranding=1&autohide=1&showinfo=0" reverse image={lowegarden} name="Lowe Garden (2020)" desc="sony fs5, samyang 85mm, 2x isco ultrastar anamorphic" />
        <VideoRow handleClickVideoSrc={() => window.open('https://youtube.com/playlist?list=PLLbezN9lQ1xAcAqg81iaujcudhWTCohEG', '_blank').focus()} videoSrc="https://www.youtube.com/embed/G29-9IzFlyQ?list=PLEB__KUjDwLNfXHNTU32ZfiakE0T67fa6&rel=0&modestbranding=1&autohide=1&showinfo=0&autoplay=1" image={motionGraph} name="Motion Graphics Playlist" desc="motion graphics works for oakland raiders and non-profit organization, after effects, Opens in new tab" /> */}
      </div>
    </div>
  );
};

export default forwardRef(Videography);
