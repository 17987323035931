import React, { createContext, useState } from 'react';

const defaultStoreState = {
  hasDark: false,
  toggleDark: () => { },
};

const ThemeContext = createContext(defaultStoreState);

const ThemeContextProvider = ({ children }) => {
  const [hasDark, setHasDark] = useState(false);

  const store = {
    dark: [hasDark, setHasDark],
  };

  return (
    <ThemeContext.Provider value={store}>
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeContextProvider };
export default ThemeContext;
