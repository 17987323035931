import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Parallax } from 'react-scroll-parallax';
import WenzelFilled from '../images/logo-filled.svg';
import WenzelOutline from '../images/logo-empty.svg';

const WENZEL_MARGIN = 180;

const SUMMARY_TEXT = 'i am a fullstack software engineer with previous work in motion graphics. i also am a videographer at heart, capturing stories and moments in motion.';

const WenzelTitle = styled(motion.h1).attrs({ className: 'text-5xl lg:text-6xl xl:text-7.5xl whitespace-nowrap font-display font-bold text-right md:text-left' })`

`;

const AnimationVariants = {
  visible: { opacity: 1, x: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, x: -300 },
};

const float = keyframes`
  0% {
    transform: translatey(0px) skewY(-20deg);
  }
  50% {
    transform: translatey(-40px) skewY(-20deg);
  }
  100% {
    transform: translatey(0px) skewY(-20deg);
  }
`;

const Polygon = styled.div`
  position: absolute;
  left: 0px;
  width: 100%;
  height: 100%;
  transform: skewY(-20deg);
  background: DDE2E1;
  ${'' /* background: transparent linear-gradient(152deg, #DDE2E1 0%, var(--unnamed-color-395c6b) 100%) 0% 0% no-repeat padding-box; */}
  ${'' /* background: transparent linear-gradient(152deg, #DDE2E1 0%, #395C6B 100%) 0% 0% no-repeat padding-box; */}
  animation: ${float} 8s ease-in-out infinite;
`;

const StripedPolygon = styled(Polygon)`
  position: absolute;
  left: 0px;
  top: 0px;
  background: initial;
  transform: skewY(-20deg) translateY(40px);
  border: 2px dashed #707070;
  animation: ${float} 10s ease-in-out infinite;
`;

const Polygons = () => (
  <motion.div className="z-0 absolute w-2/6 h-full left-0">
    <StripedPolygon />

    {/* <Polygon /> */}
  </motion.div>
);

const WenzelsContainer = styled(motion.div)`
  position: relative;
  &:before {
    content: 'art by jaerica';
    text-transform: uppercase;
    font-size: 0.5rem;
    position: absolute;
    bottom: -20px;
    margin-right: 144px;
    color: #333;
  }
`;

const LeftWenzel = styled(motion.img)`
  margin-right: -${WENZEL_MARGIN}px;
  cursor: wait;
  ${'' /* filter: invert(1) */}
`;

const CenterWenzel = styled(motion.img)`
  z-index: 10;
  cursor: help;
`;

const RightWenzel = styled(motion.img)`
  margin-left: -${WENZEL_MARGIN}px;
  cursor: crosshair;
`;

const Wenzels = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView]);

  return (
    <WenzelsContainer
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={AnimationVariants}
      className=" h-80 col-span-2 z-10 flex flex-row justify-center col-start-2 col-end-3"
    >
      <LeftWenzel
        whileHover={{
          x: -50,
          transition: { duration: 1 },
        }}
        src={WenzelOutline}
      />
      <CenterWenzel
        src={WenzelFilled}
      />
      <RightWenzel
        whileHover={{
          x: 100,
          rotate: 20,
          transition: { duration: 1 },
        }}
        src={WenzelOutline}
      />
    </WenzelsContainer>
  );
};

const Summary = () => (
  <div className="p-12 pt-0 xl:p-0 w-full  h-screen/3 xl:h-screen flex flex-col justify-center items-center mb-48">
    <div className="flex flex-col-reverse justify-end xl:grid xl:grid-rows-1 xl:grid-cols-2 w-full h-full flex-1">
      <div className="container opacity-0 xl:opacity-100 flex items-center justify-center">
        <Polygons />
        <Parallax
          y={[-30, 30]}
          className="absolute grid w-screen grid-cols-8 left-0"
          styleInner={{
            gridColumnStart: 3,
            gridColumnEnd: 4,
          }}
        >
          <Wenzels />
        </Parallax>
      </div>

      <div className="container flex items-center justify-center z-10 ">
        <div className="container">

          <WenzelTitle
            className=""
            whileHover={{ y: -10 }}
            transition={{
              duration: 0.5,
            }}
          >
            wenzel
          </WenzelTitle>
          <div className="container my-7 max-w-xl text-right md:text-left">
            <p className="text-2xl uppercase">
              {SUMMARY_TEXT}
            </p>

          </div>
        </div>
      </div>
    </div>

  </div>
);

export default Summary;
