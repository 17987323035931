import React from 'react';
import styled, { keyframes } from 'styled-components';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { Parallax } from 'react-scroll-parallax';

import floatingWenzel from '../images/self.webp';
import bg from '../images/bg.webp';

const BGImage = styled.div`
  background-image: url(${bg});
`;

const float = keyframes`
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
`;

const FloatingWenzel = styled.img`
  min-width: 37rem;
  animation: ${float} 6s ease-in-out infinite;
`;

const HighlightedH3 = styled.h3.attrs({
  className: 'text-xl xl:text-2xl  ml-6 mb-6 uppercase tracking-even-wider whitespace-nowrap',
})`
  position: relative;
  display: inline-block;
  cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: -1.5rem;
    right: 0;
    top: 65%;
    background: ${(p) => p.colorOne};
    opacity: 0.2;
    z-index: -1;
    transition: 0.15s;
  }
  &:hover:before {
    background: ${(p) => p.colorTwo};
    opacity: 1;
    right: -3rem;
    top: 55%;
  }
`;

const HeroTitle = ({

  scrollToSoftware,
  scrollToVideography,
}) => (
  <div className=" w-full h-screen flex flex-col justify-center items-center">
    <BGImage className="hidden xl:block w-2/6 absolute top-0 right-0 bottom-16 bg-right bg-no-repeat bg-cover z-0" />

    <div className="flex flex-col xl:grid xl:grid-cols-2 w-full  h-full flex-1">
      {/* <div className='container flex flex-col justify-center items-center text-left'> */}
      <div className="container flex items-center w-full h-full">
        <Parallax y={[-10, 10]}>

          <div className="xl:pl-48 lg:pl-36 px-12 z-20">
            <motion.h1
              className="text-5xl lg:text-6xl xl:text-7.5xl font-display font-bold mb-8"
              initial={{ y: 100, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{
                duration: 0.5,
              }}
            >
              Hi, I&apos;m Wenzel Lowe
            </motion.h1>
            <HighlightedH3
              as={motion.h3}
              whileHover={{
                scale: 1.1,
                rotate: -1,
                transition: {
                  delay: 0,
                },
              }}
              initial={{
                x: -150,
                opacity: 0,
              }}
              transition={{
                x: {
                  delay: 0.75,
                  duration: 1,
                },
                opacity: {
                  delay: 0.9,
                  duration: 0.6,
                },
              }}
              animate={{ x: 0, opacity: 1 }}
              onClick={scrollToSoftware}
              colorOne="#395c6b"
              colorTwo="#39a0ed"
            >
              software engineer
            </HighlightedH3>
            <br />
            <HighlightedH3
              as={motion.h3}
              whileHover={{
                scale: 1.1,
                rotate: -1,
                transition: {
                  delay: 0,
                },
              }}
              initial={{
                x: -150,
                opacity: 0,
              }}
              transition={{
                x: {
                  delay: 1.15,
                  duration: 1,
                },
                opacity: {
                  delay: 1.1,
                  duration: 0.6,
                },
              }}
              animate={{ x: 0, opacity: 1 }}
              onClick={scrollToVideography}
              colorOne="#9C88FF"
              colorTwo="#9C88FF"
            >
              videographer
            </HighlightedH3>
            <br />
            {/* <HighlightedH3
              as={motion.h3}
              whileHover={{
                scale: 1.1,
                rotate: -1,
                transition: {
                  delay: 0,
                },
              }}
              initial={{
                x: -150,
                opacity: 0,
              }}
              transition={{
                x: {
                  delay: 1.2,
                  duration: 1,
                },
                opacity: {
                  delay: 1.1,
                  duration: 0.6,
                },
              }}
              animate={{ x: 0, opacity: 1 }}
              colorOne="#13C4A3"
              colorTwo="#13C4A3"
              style={{
                cursor: 'initial',
              }}
            >
              designer
            </HighlightedH3> */}
          </div>
        </Parallax>
      </div>
      <div className="hidden xl:flex z-10 p-12 mt-24 pl-48  justify-center align-center">
        <Parallax y={[-20, 20]}>
          <FloatingWenzel src={floatingWenzel} />

        </Parallax>
      </div>
    </div>
  </div>
);

HeroTitle.propTypes = {
  scrollToSoftware: PropTypes.func.isRequired,
  scrollToVideography: PropTypes.func.isRequired,
};

export default HeroTitle;
