import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import {
  Instagram, GitHub, Linkedin, FileText,
} from 'react-feather';
import ThemeContext from '../context/ThemeContext';

const IconContainer = styled.div.attrs({
  className: `h-4 xl:h-6 mb-4 ${(props) => (props.hasDark ? 'text-white' : 'color-primary')}`,
})`
  cursor: pointer;
`;

const BorderedDiv = styled.div`
  ${'' /* width: 24px; */}
`;

const SocialLinks = () => {
  const { dark: [hasDark] } = useContext(ThemeContext);

  return (
    <div className="hidden xl:fixed left-12 xl:left-24 bottom-4 xl:flex flex-col-reverse z-50">
      <BorderedDiv className={`duration-1000 h-12 xl:h-24 w-px border-r ${hasDark ? 'border-grey-600' : 'border-primary'} self-center`} />
      <IconContainer
        as={motion.a}
        href="https://instagram.com/wnzls"
        target="_blank"
        whileHover={{ scale: 1.2, rotate: -10 }}
        className={`h-4 xl:h-6 mb-4 ${hasDark ? 'text-white' : 'color-primary'}`}
      >
        <Instagram className="stroke-1 w-4 xl:w-6" />
      </IconContainer>
      <IconContainer
        as={motion.a}
        href="https://github.com/lowewenzel"
        target="_blank"
        whileHover={{ scale: 1.2, rotate: -10 }}
        className={`h-4 xl:h-6 mb-4 ${hasDark ? 'text-white' : 'color-primary'}`}
      >
        <GitHub className="stroke-1 w-4 xl:w-6" />
      </IconContainer>
      <IconContainer
        as={motion.a}
        href="https://linkedin.com/in/wnzl"
        target="_blank"
        whileHover={{ scale: 1.2, rotate: -10 }}
        className={`h-4 xl:h-6 mb-4 ${hasDark ? 'text-white' : 'color-primary'}`}
      >
        <Linkedin className="stroke-1 w-4 xl:w-6" />
      </IconContainer>
      <IconContainer
        as={motion.a}
        href="mailto:hi@wnzl.dev"
        target="_blank"
        whileHover={{ scale: 1.2, rotate: -10 }}
        className={`h-4 xl:h-6 mb-4 ${hasDark ? 'text-white' : 'color-primary'}`}
      >
        <FileText className="stroke-1 w-4 xl:w-6" />
      </IconContainer>
    </div>
  );
};

export default SocialLinks;
