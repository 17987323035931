import React from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import Layout from '../components/Layout';
import { ThemeContextProvider } from '../context/ThemeContext';

import Header from '../components/Header';
import HeroTitle from '../components/HeroTitle';
import Summary from '../components/Summary';
import Software from '../components/Software';
import SocialLinks from '../components/SocialLinks';
import Videography from '../components/Videography';
import Footer from '../components/Footer';

const IndexPage = () => {
  const scrollToRef = (ref) => {
    // ugh
    if (ref === 'software') {
      document.getElementById('software').scrollIntoView();
    } else if (ref === 'videography') {
      document.getElementById('videography').scrollIntoView();
    }
  };

  return (
    <ThemeContextProvider>
      <ParallaxProvider>
        <Layout />
        <main className="overflow-hidden z-10">

          <div className="z-10 w-full">
            <SocialLinks />
            <Header />

            <HeroTitle scrollToSoftware={() => scrollToRef('software')} scrollToVideography={() => scrollToRef('videography')} />

            <Summary />

            <Software />

            <Videography />
            <Footer />
          </div>
        </main>
      </ParallaxProvider>
    </ThemeContextProvider>
  );
};

export default IndexPage;
