import React from 'react';

const Footer = () => (
  <div className="w-screen bg-black flex justify-center pb-4 z-20">
    <span className="text-white uppercase text-center">
      ©
      {' '}
      {new Date().getFullYear()}
      {' '}
      Wenzel Lowe.
    </span>
  </div>
);

export default Footer;
